const PAYMENT_TYPES = {
  FIAT: [
    { field: 'UAH', value: 'UAH' },
    { field: 'EUR', value: 'EUR' },
    // { field: 'USD', value: 'USD' },
  ],
  CRYPTO: [
    { field: 'BTC', value: 'BTC' },
    { field: 'ETH', value: 'ETH' },
    { field: 'USDT', value: 'USDT' },
    { field: 'USDC', value: 'USDC' },
    { field: 'BRNC', value: 'BRNC' },
    { field: 'LTC', value: 'LTC' },
  ],
  COINPAY: [{ field: 'COINPAY', value: 'Kauri Finance' }],
  ALL: [
    { field: 'COINPAY', value: 'Kauri Finance' },
    { field: 'BTC', value: 'BTC' },
    { field: 'ETH', value: 'ETH' },
    { field: 'USDT', value: 'USDT' },
    { field: 'USDC', value: 'USDC' },
    { field: 'BRNC', value: 'BRNC' },
    { field: 'LTC', value: 'LTC' },
    { field: 'UAH', value: 'UAH' },
    { field: 'EUR', value: 'EUR' },
    // { field: 'USD', value: 'USD' },
  ],
};

export default PAYMENT_TYPES;
