export const validateForm = {
  methods: {
    validateForm() {
      return new Promise((resolve, reject) => {
        if (this.$refs && this.$refs.form) {
          let isValid = this.$refs.form.validate();
          if (isValid) {
            resolve(isValid);
          } else {
            reject(new Error("The form didn't pass validation"));
          }
        } else {
          reject(new Error("Didn't found form ref"));
        }
      });
    },
  },
};
