<template>
  <section class="Invoice_root">
    <v-form ref="form" class="Invoice_wrapper">
      <div class="Invoice_logo">
        <img alt="invoice page logo" src="@/assets/img/_common/financeLogoBlue.svg" />
      </div>
      <div class="Invoice_title">invoice payment</div>
      <div class="Invoice_border"></div>
      <div class="Invoice_inner">
        <div class="Invoice_merchant">
          Merchant:
          {{
            invoice && invoice.order_details && invoice.order_details.details && invoice.order_details.details.destination_account
              ? invoice.order_details.details.destination_account
              : '--'
          }}
        </div>
        <div class="Invoice_inner__border"></div>
        <div class="Invoice_inner__item">
          <div class="Invoice_inner__item-order">
            <span class="Invoice_inner__label">Invoice Amount</span>
            {{ invoice.amount }} {{ invoice.currency }}
          </div>
          <div class="Invoice_inner__item-order Invoice_inner__item-order--id">
            <span class="Invoice_inner__label">Order</span>
            {{ invoice && invoice.order_details && invoice.order_details.internal_id ? invoice.order_details.internal_id : '' }}
          </div>
        </div>
        <div class="Invoice_inner__border"></div>
        <div class="Invoice_inner__input">
          <Select :value="payment_options[0]" :items="payment_options" name="payment_type" label="Payment method" @change="handleType" />
        </div>
        <div class="Invoice_inner__input mt-10" v-if="['USDT','USDC','BRNC'].includes(invoiceForm.payment_type.value)">
          <Select :value="selectedNetwork" :items="networks" name="network" label="Network" @change="selectedNetwork = $event.value" />
        </div>
        <div class="Invoice_inner__border"></div>
        <TextFieldInput
          label="email for receipt"
          hideDetails
          name="email"
          placeholder="Email address"
          :value="invoice.destination_account_email"
          :disabled="true"
        />
        <div class="Invoice_inner__description">
          If the payment amount exceeds the amount stated above you will received fund recovery instructions via email
        </div>
        <div class="Invoice_inner__checkbox">
          <Checkbox :value="acceptRules" @input="handleAcceptRules">
            <div class="Registration_checkbox-label" slot="label">
              I accept the terms of <router-link tag="a" to="/docs/UserAgreement.pdf" target="_blank">user agreement</router-link>
            </div>
          </Checkbox>
        </div>
        <OutlineButton
          class="outline-button-default outline-button-full-width outline-button"
          text="Next"
          @click="handleSubmitMethod"
        />
        <div class="Invoice_countdown">
          <div class="Invoice_countdownWrapper">
            <iconCountdown fillColor="#ff556d" />
            <div class="Invoice_countdown__inner">
              <vue-countdown-timer
                @end_callback="getInvoiceInfo()"
                v-if="expired.start"
                :start-time="+expired.start"
                :end-time="+expired.end"
                :interval="1000"
              ></vue-countdown-timer>
            </div>
          </div>
          When time runs out - invoice will become not payable
        </div>
      </div>
    </v-form>
  </section>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import Select from '@/components/_common/FormElements/Select/Select';
import { iconCountdown } from '@/assets/icons/common';
import PAYMENT_TYPES from './PaymentTypes';
import TextFieldInput from '@/components/_common/FormElements/TextFieldInput/TextFieldInput';
import Checkbox from '@/components/_common/FormElements/Checkbox/Checkbox';
import OutlineButton from '@/components/_common/FormElements/OutlineButton/OutlineButton';
import { showAppNotification } from '@/utils/appNotification';
import { validateForm } from '@/mixins/validateForm';
import { isCrypto } from '@/store/balance/_helpers/currencyTypeHelper';

export default {
  data() {
    return {
      invoice_id: '',
      PAYMENT_TYPES,
      payment_options: [],
      acceptRules: false,
      expired: {
        start: 0,
        end: 0,
      },
      invoiceForm: {
        payment_type: '',
      },
      selectedNetwork: "ERC20",
      networks: ['ERC20', 'TRC20', 'BEP20']
    };
  },
  mixins: [validateForm],
  components: {
    Select,
    TextFieldInput,
    Checkbox,
    OutlineButton,
    iconCountdown,
  },
  methods: {
    ...mapActions('invoicing', ['apiGetInvoiceInfo', 'apiPayPublicInvoice', 'apiPayPublicCalculateInvoice']),
    ...mapActions('invoicing', [ 'updateInvoiceGatewayForm' ]),
    getInvoiceInfo() {
      if (this.$route.params && this.$route.params.id) {
        this.apiGetInvoiceInfo(this.$route.params.id).then(res => {
          this.paymentTypes();
          this.invoice_id = this.$route.params.id;
          this.expired.start = moment(new Date()).format('X');
          this.expired.end = moment(this.invoice.expired_on).format('X');
          if (res.data.order_details.status !== 'WAITING_FOR_CONFIRMATION' && res.data.order_details.status !== 'NEW') {
            this.$router.push({ name: 'invoice.statements.statement', params: res });
          }
        });
      }
    },
    paymentTypes() {
      const paymentType = this.invoice.payment_option;
      this.payment_options = this.PAYMENT_TYPES[paymentType];
      this.invoiceForm.payment_type = this.PAYMENT_TYPES[paymentType][0];
    },
    handleAcceptRules(value) {
      this.acceptRules = value;
    },
    handleSubmitMethod() {
      this.validateForm().then(() => {
        if (!this.acceptRules) {
          showAppNotification('error', 'Please, accept terms of user agreement!');
        } else {
          const currency = this.invoiceForm.payment_type.value;
          if (currency === 'Kauri Finance') {
            this.$router.push({ name: 'auth', query: { invoice: this.invoice_id } });
          } else if (['EUR', 'USD'].includes(currency)) {
            this.updateInvoiceGatewayForm({ value: this.invoice_id, field: 'order_id' })
            this.apiPayPublicCalculateInvoice(this.getCalculateParams).then(res => {
              this.updateInvoiceGatewayForm({ value: res.data.pay_amount, field: 'amount' })
              this.updateInvoiceGatewayForm({ value: res.data.pay_currency, field: 'currency' })
              return this.$router.push({ name: 'invoice.gateway' });
            })
          } else {
            const data = ['USDT','USDC','BRNC'].includes(this.invoiceForm.payment_type.value)
              ? { currency: currency, order_id: this.invoice_id, payment_method: this.selectedNetwork }
              : { currency: currency, order_id: this.invoice_id };

            this.apiPayPublicInvoice(data).then(res => {
              const invoiceData = res;
              invoiceData.invoice_amount = this.invoice.amount;
              invoiceData.invoice_currency = this.invoice.currency;
              invoiceData.invoice_id = this.invoice_id;
              if(['UAH'].includes(invoiceData.data.pay_currency)) {
                window.open(invoiceData.data.url, '_blank')
              }
              this.$router.push({ name: 'invoice.waiting', params: invoiceData });
            });
          }
        }
      });
    },
    handleType(value) {
      this.invoiceForm.payment_type = value;
    },
  },
  computed: {
    ...mapGetters('invoicing', ['invoice']),
    invoiceInfo() {
      return this.invoice;
    },
    getCalculateParams() {
      // payment_method=ERC20
      return {
        order_id: this.invoice_id,
        currency: this.invoiceForm.payment_type.value,
      }
    }
  },
  mounted() {
    this.getInvoiceInfo();
  },
};
</script>

<style lang="scss">
@import './Invoice.scss';

.Invoice_root {
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, #14283f 0%, #050e1a 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
  overflow: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}
</style>
